.Intro {
  display: flex;
  height: 77vh;
  margin-top: 6rem;
  margin-left: 40px;
}

/* left side styling */
.i-left {
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  gap: 2rem;
}

.i-name {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.i-name> :nth-child(1) {
  color: var(--black);
  font-weight: bold;
  font-size: 3rem;
}

.i-name> :nth-child(2) {
  color: var(--orange);
  font-weight: bold;
  font-size: 3rem;
}

.i-name> :nth-child(3) {
  font-weight: 100;
  font-size: 14px;
  color: var(--gray);
  margin-top: 10px;
}

.i-button {
  width: 6rem;
  height: 2rem;
}

.i-icons {
  gap: 0rem;
  position: fixed;
  display: inline-grid;
  left: 0px;
}

.i-icons>* {
  transform: scale(0.5);
}

.i-icons>*:hover {
  cursor: pointer;
}

/* right side styling */
.i-right {
  flex: 1;
  position: relative;
}

.i-right>* {
  position: absolute;
  z-index: 1;
}

.i-right> :nth-child(1) {
  transform: scale(0.69);
  left: -15%;
  top: -9%;
}

.i-right> :nth-child(2) {
  top: -4.6rem;
  transform: scale(0.67);
  left: -3rem;
}

.i-right> :nth-child(3) {
  transform: scale(1.4);
  left: 28%;
}

.i-right> :nth-child(4) {
  transform: scale(0.62);
  top: -19%;
  left: -24%;
  border-radius: 50%;
  padding: 0;

}

.i-right>img.myimage {


  /* border-bottom-right-radius: 110px 47px; */
  width: 285px;
  /* border-bottom-left-radius: 41px 38px; */
  transition: transform .8s;
  /* margin-bottom: 95px; */
  margin-left: 50px;
  padding: 3px;
  filter: blur(0px) brightness(120%) contrast(110%);

}

.i-right>img {
  box-shadow: 0px -1px 6px #4e5050;
  border-radius: 20px;

}

.i-right>img.myimage:hover {
  transform: scale(1.5);
  filter: brightness(130%);

  /* transition: all .2s ease-out; */


}

/* blur */
.blur {
  position: absolute;
  width: 22rem;
  height: 14rem;
  border-radius: 50%;
  background: #edd0ff;
  filter: blur(212px);
  z-index: -9;
  top: -18%;
  left: 56%;
  filter: blur(72px);
}

.zoom {
  text-decoration: none;
  /* padding: 30px; */
  transition: transform .8s;
  /* Animation */
  /* width: 200px; */
  height: 190px;
  margin: 0 auto;
  padding: 30px;
}

.zoom>span {
  font-weight: 100;
  font-size: 19px;
  color: var(--gray);
  margin-top: 10px;

}

.zoom:hover {
  transform: scale(1.01);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  /* background-color: aliceblue; */
  border-radius: 15px 20px;
  box-shadow: 3px;
  box-shadow: -1px -1px 15px#4e5050;
  padding: 30px;
}

.button_ {
  padding: 40px;
}

/* .button_>a{
  padding: 20px;

} */
.resme {
  display: flex;
  padding: 15px;
}

@media screen and (max-width: 767px) {
  .Intro {
    flex-direction: column;
    gap: 3rem;
    height: 64rem;
    margin: 0px;
  }

  .i-right {
    transform: scale(0.8);
    left: -5rem;
  }

  .i-right .blur {
    display: none;
  }

  .floating-div:nth-of-type(1) {
    /* display: none; */
    top: -7rem !important;
    ;
  }

  .i-icons {
    position: fixed;
    display: flex;
    bottom: 0px;
    background-color: #0c0a0a;
    border-radius: 5px;
    /* box-shadow: inset -1px -1px 15px #4e5050; */
    width: 100%;
    padding-left: 57px;
    opacity: 20;
    z-index: 999;
  }

  .i-right>img {
    /* box-shadow: -1px -1px 15px#4e5050;
    box-shadow: inset -1px -1px 15px#4e5050;
    border-radius: 50px;
    width: 250px; */
  }

  .floating-div {
    display: none;
  }

  .floatingDiv {
    display: none !important;
  }

  .i-right>img.myimage {
    width: 330px;
    border-bottom-left-radius: 41px 38px;
    transition: transform .8s;
    /* margin-bottom: 95px; */
    margin-left: 9px;
    /* padding: 0px; */
    height: auto;
    /* filter: blur(5px);
    filter: brightness(140%);  */

  }

  .resume {
    display: inline;
    padding-top: 235px;
    padding-left: 0px;
  }

  .button_ {
    padding: 40px;
  }

  .i-button {
    width: 7rem;
    height: 2.8rem;
  }

  .zoom {
    text-decoration: none;
    padding: 0px;
    transition: transform .8s;
    /* Animation */
    /* width: 200px; */
    height: 100px;
    margin: 0 auto;
  }

  .zoom>span {
    font-weight: 100;
    font-size: 20px;
    color: var(--gray);
    margin-top: 10px;

  }

}