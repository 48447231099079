.services {
  padding: 0 3rem 0 3rem;
  display: flex;
  height: 30rem;
  margin-bottom: 8rem;
  margin-top: 9rem;
  margin-bottom: 13rem;
  /* scroll view */
  padding-top: 9rem;
}
/* left side */
.awesome {
  display: flex;
  flex-direction: column;
  position: relative;
  /* gap: 20px; */
}

.awesome > :nth-child(1) {
  color: var(--black);
  font-size: 2.5rem;
  font-weight: bold;
}
.awesome > :nth-child(2) {
  color: var(--orange);
  font-size: 2.5rem;
  font-weight: bold;
}
.awesome > :nth-child(3) {
  color: var(--gray);
  font-size: 14px;
  margin-top: 1rem;
}
.s-button {
  width: 8rem;
  height: 2rem;
  margin-top: 1rem;
}
/* right sided */
.cards {
  position: relative;
  margin-left: 249px;
}
.cards > * {
  position: absolute;

}
.card{
  transition: transform .8s; /* Animation */

}

.card:hover{
  transform: scale(1.2); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  /* background-color: aliceblue; */
  border-radius: 10px 15px;
  box-shadow: 5px;
  box-shadow: 10px 5px 5px grey;
}

/* blur */
.s-blur2 {
  left: 14rem;
  top: 8rem;
}

.s-blur1 {
  top: 13rem;
  left: -18rem;
}
.text{
  width: 200px;
  height: auto;
  width: 325px;
}
@media screen and (max-width: 767px) {
  .services {
    /* margin-top: 323px;
    flex-direction: column;
    gap: 2rem;
    height: 24rem;
    padding: 0; */
    display: none;
  }
  .s-button{
    width: 9rem !important;
    height: 4rem !important;
  }
 
  .awesome>span {
   font-size: 25px !important;
   display: inline !important;
  }

  .awesome > :nth-child(2) {
   display: inline;
  }

  .cards {
    display: flex;
    flex-direction: column;
    gap: 17rem;
  }
  .cards > * {
    position: static;
  }
}
