.portfolio {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  transition: all 0.5s ease-in-out;


}

/* heading */
.portfolio span:nth-of-type(1) {
  color: var(--black);
  font-size: 2rem;
  font-weight: bold;
}

.portfolio span:nth-of-type(2) {
  color: var(--orange);
  font-size: 2.5rem;
  font-weight: bold;
}

/* slider */
.portfolio .swiper {
  overflow: visible !important;
}

.portfolio-slider {
  margin-top: 3rem;
  width: 100%;
}

.portfolio-slider .swiper-slide {
  width: 20rem;
}

.portfolio img {
  /* width: 1000px;
  filter: drop-shadow(-12px 15px 13px rgba(0, 0, 0, 0.25));
  border-radius: 19px;
  box-shadow: 2px 2px 2px white;
  margin-left: 100px;
  height: 477px;
  filter: brightness(70%);
  margin-top: 25px;
  transition: all 0.5s ease-in-out; */
}

.slider-img>img {
  width: 1050px;
  filter: drop-shadow(-12px 15px 13px rgba(0, 0, 0, 0.25));
  border-radius: 19px;
  box-shadow: 2px 2px 2px white;
  margin: 30px;
  height: 477px;
  filter: brightness(70%);
  margin-top: 25px;
  transition: all 0.5s ease-in-out;
}

.swip:hover {
  width: 1050px;
  filter: brightness(110%);
  box-shadow: 0px -5px 20px 11px gray;
  transition: all 0.5s ease-in-out;
}

.left {
  width: 50px;
  height: 39px;
  border-radius: 10px;
  float: left;
  margin-top: 238px;
  background-color: orange;
  padding-top: 6px;
  padding-left: 14px;
}

.right {
  width: 50px;
  height: 39px;
  border-radius: 10px;
  float: right;
  margin-top: 238px;
  background-color: orange;
  padding-top: 6px;
  padding-left: 14px;
}

.slider-img>p {
  /* margin-right: 50px; */
  left: 20px;
  margin-left: 100px;
  ;
  width: 1000px;
}

.image {
  filter: blur(5px);
  transition: filter 0.3s ease-in-out;
}

.image.active {
  filter: none;
}

.image.inactive {
  filter: blur(10px);
}

.caption {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.image-wrapper.active .caption {
  opacity: 1;
}

.image-wrapper.inactive .caption {
  opacity: 0;
}

@media screen and (max-width:767px) {
  .portfolio {
    /* height: 35vh; */
    display: none;
  }

  .slider-img>img {
    width: 306px;
    filter: drop-shadow(-12px 15px 13px rgba(0, 0, 0, 0.25));
    border-radius: 19px;
    box-shadow: 2px 2px 2px white;
    margin-left: 0px;
    height: 270px;
    margin-top: 25px;
    transition: all 0.5s ease-in-out;
  }
  
  .swip:hover {
    width: 270px;
    box-shadow: 0px -1px 2px 1px gray;
    transition: all 0.5s ease-in-out;
  }

  .slider-img>p {
    margin-left: 0px;
    width: 282px;
    margin-bottom: 2px;
    margin-top: -27px;
    font-size: 13px;
  }
  .right {
    width: 40px;
    height: 24px;
    border-radius: 10px;
    float: right;
    margin-top: 0px;
    background-color: orange;
    padding-top: 6px;
    padding-left: 14px;
  }
  .left{
    display: none;
  }

}