.n-wrapper {
  height: 10vh;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  top: 0;
}
.n-left {
  flex: 1;
  align-items: center;
  justify-content: left;
  display: flex;
  gap: 2rem;
}

.n-name {
  font-size: 1.3rem;
  font-weight: bold;
  border-radius: 100%;
  border: 3px solid white;
  width: 110px;
  height: 100px;
  margin-top: 30px;
}
.n-right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 400;
}

.n-list {
  flex: 10;
}
.n-list > ul {
  display: flex;
  gap: 2rem;
  margin-right: 4rem;
  list-style: none;
}
.n-list > ul>li>a {
 color: #fff;
 font-weight: 501;
 font-size: 14px;
 text-transform: uppercase;
}
.hamburger-react>:nth-child(1),.hamburger-react>:nth-child(2), .hamburger-react>:nth-child(3){
color: #fff;
}

.n-list > ul :hover {
  color: var(--orange);
  cursor: pointer;
}

.n-button {
  flex: 2;
}

.n-name>img{
  width: 110px;
  height: 100px;
  border-color: white;
  border-width: 1px;
  padding: 0px;
  border-radius: 100%;
  padding-bottom: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  /* padding-top: 50px; */
}
.n-name>img:hover{
  filter: brightness(120%);
}
.n-name>span{
  background-color: #cac5c5;
  border-radius: 17px;
  display: flex;
  padding: 5px;
  margin: -21px;
  padding-left: 4px;
}

@media screen and (max-width: 760px) {
  .n-list {
    display: none;
  }
  .n-name{
    display: none;
  }
  .n-wrapper {
    height: 0vh;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    top: 0;
  }
  .hamburger-react{
    display: none;
  }
}
