.experience {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  height: 50vh;
  margin-bottom: 2rem;
  /* darkmode */
  color: var(--black);
  padding-top: 90px;
}
.achievement{
    display: flex;
    flex-direction: column;    
    align-items: center;
}
.circle {
    width: 4.5rem;
    height: 4.5rem;
    background: white;
    border-radius: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 0rem;
    transition: all 0.5s ease-in-out;
}
.circle::before {
    border-radius: 100%;
    content: '';
    background-image: linear-gradient(to bottom, #87e6fb 0%, #ffc05c 100%);
    top: -8px;
    left: -8px;
    bottom: -8px;
    right: -8px;
    position: absolute;
    z-index:-1;
    box-shadow: var(--boxShadow);
}

.circle:hover{
    background-color:rgb(64, 61, 56);
    /* color: #000; */
}

.achievement span:nth-of-type(2){
    color: var(--orange);
}


@media screen and (max-width: 767px) {
    .experience{
        transform: scale(0.7);
        padding-top: 0px;
        margin-top: -395px;
    
    }
}